// IMPORT DEPENDENCIES
import {BASE_URL, BASE_FILE_UPLOAD_URL} from '../../urls/base/base'

export const ADD_SEMT_URL = `${BASE_URL}/admin/addsemt`;
export const GET_SEMT_URL = `${BASE_URL}/admin/getsemt`;
export const DELETE_SEMT_URL = `${BASE_URL}/admin/deletesemt`;
export const UPDATE_SEMT_URL = `${BASE_URL}/admin/updatesemt`;
export const UPLOAD_FILE_URL = `${BASE_FILE_UPLOAD_URL}/esc/uploadfile`;
export const ADD_ILAN_URL = `${BASE_URL}/admin/addilan`;
export const GET_ILAN_URL = `${BASE_URL}/admin/getilan`;
export const SET_VIP_STATUS_URL = `${BASE_URL}/admin/setvipstatus`;
