import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIlanService, setVipStatusService} from '../../actions/home/home';
import { Link } from "react-router-dom";
import Modal from 'react-modal';
import Switch from "react-switch";

import Navbar from "../../components/navbar/navbar";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Loader from "../../components/loader/loader";


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',

  },
};


Modal.setAppElement('#root');

const IlanList = () => {

  const dispatch = useDispatch();

  const token = useSelector(state => state.AuthReducer.token);
  const ilan = useSelector(state => state.HomeReducer.ilan);
  const loadingilan = useSelector(state => state.HomeReducer.loadingilan);
  // const deletesemtmodal = useSelector(state => state.HomeReducer.deletesemtmodal);
  // const loadingdeletesemt = useSelector(state => state.HomeReducer.loadingdeletesemt);
  // const updatesemtmodal = useSelector(state => state.HomeReducer.updatesemtmodal);
  // const loadingupdatesemt = useSelector(state => state.HomeReducer.loadingupdatesemt);


  // const [semtData, setSemtData] = useState([])
  // const [name, setName] = useState("");
  // const [error, setError] = useState(false);


  useEffect(() => {
    dispatch(getIlanService(token))
  }, []);

  const handleSwitch = (switchstatus,item) => {
    dispatch(setVipStatusService(item.id, !switchstatus, token))
  }

  // const openConfirmDelete = (item) => {
  //   setSemtData(item)
  //   dispatch(openDeleteSemtModal())

  // }

  // const closeModal = () => {
  //   dispatch(closeDeleteSemtModal())
  // }

  // const handleEvetDelete = () => {
  //   dispatch(deleteSemtService(semtData.id, token))
  // }

  // const closeModalUpdate = () => {
  //   dispatch(closeUpdateSemtModal())
  // }

  // const openEditSemt = (item) => {
  //   setSemtData(item)
  //   setName(item.name)
  //   dispatch(openUpdateSemtModal())
  // }

  // const handleName = (e) => {
  //   setName(e.target.value)
  // }

  // const handleEvetUpdate = () => {
  //   if (name !== "" && name.trim() !== "") {
  //     dispatch(updateSemtService(semtData.id, name, token))
  //   }
  //   else {
  //     setError(true)
  //   }
  // }

  let IlanList = ilan.map((item, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td> <img style={{width:40, height:40, borderRadius:50}} src={item.url1}/></td>
        <td>{item.title}</td>
        <td>{item.name}</td>
        <td>{item.semt.name}</td>
        <td>{item.whatssap}</td>
        <td> <Switch onChange={() => handleSwitch(item.popular, item)} checked={item.popular} /></td>
        <td>{item.contactlieu}</td>
        <td>{item.yas}</td>
        <td>{item.zaman} gün</td>
        <td>
          <ul class="list-inline applicant-list-actions">
            <li class="list-inline-item">
              <a href="#" class="text-muted">
                <i
                  onClick={() => {
                   // openEditSemt(item);
                  }}
                  class="mdi mdi-border-color edit-pen"></i>
              </a>
            </li>

            <li class="list-inline-item">
              <a class="text-custom">
                <i
                  onClick={() => {
                   // openConfirmDelete(item);
                  }}
                  class="mdi mdi-delete delete-box"
                ></i>
              </a>
            </li>
          </ul>
        </td>
      </tr>
    );
  });

  return (
    <>
      <div id="wrapper">
        <Header />
        <Navbar />
        <div class="content-page">
          <div class="content">
            <div class="input-group-append btn-go-back">
              <Link to="/">
                <button class="btn" type="submit">
                  <i class="mdi mdi-arrow-left"></i>
                </button>
              </Link>
            </div>

            <div class="input-group-append btn-go-back">
              <Link to="/">
                <button class="btn" type="submit">
                  <i class="mdi mdi-arrow-left"></i>
                </button>
              </Link>
            </div>

            <div class="container-fluid">
              <div className="table-responsive">
                <table className="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Resim</th>
                      <th>İlan başliği</th>
                      <th>İsim</th>
                      <th>Semt</th>
                      <th>Whatssap</th>
                      <th>Vip</th>
                      <th>Görüşme yeri</th>
                      <th>Yaş</th>
                      <th>Ilan Suresi</th>
                      <th>Aksyon</th>
                    </tr>
                  </thead>
                  <tbody className="set-relative">
                    {loadingilan ?
                      <div className="loader-parent">
                        <Loader />
                      </div>
                      : IlanList
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        isOpen={deletesemtmodal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 500, height: 100, padding: 0, marginTop: -20 }}>
          <h2>Semt Silmek</h2>
          <button onClick={closeModal}>X</button>
        </div>

        <div>
          <p> o semt silmek istiyor musun ?</p>
        </div>

        <div>
          {
            loadingdeletesemt ?
              <div className="loader-parent">
                <Loader />
              </div>
              :
              <div
                onClick={handleEvetDelete}
                class="btn btn-primary"
                style={{ marginTop: 10 }}
              >
                Evet
              </div>
          }

        </div>

      </Modal>

      <Modal
        isOpen={updatesemtmodal}
        onRequestClose={closeModalUpdate}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 500, height: 100, padding: 0, marginTop: -20 }}>
          <h2>Semt Güncellemek</h2>
          <button onClick={closeModalUpdate}>X</button>
        </div>

        <div>
          <div class="form-group col-md-6">
            <label for="inputAddress2" class="col-form-label">
              semt Adı
            </label>
            <input
              type="text"
              value={name}
              onChange={handleName}
              class="form-control"
              id=""
              placeholder="Semt Adı"
            />
          </div>
        </div>

        <div>
          {
            loadingupdatesemt ?
              <div className="loader-parent">
                <Loader />
              </div>
              :
              <div
                onClick={handleEvetUpdate}
                class="btn btn-primary"
                style={{ marginTop: 10 }}
              >
                Guncelle
              </div>
          }

        </div>

      </Modal> */}
      <Footer />
    </>
  );
};

export default IlanList;
