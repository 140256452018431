import Decode from 'jwt-decode';


//IMPORT URL
import { ADD_SEMT_URL, GET_SEMT_URL, UPDATE_SEMT_URL, DELETE_SEMT_URL, UPLOAD_FILE_URL, ADD_ILAN_URL, GET_ILAN_URL, SET_VIP_STATUS_URL} from "../../urls/home/home";

// IMPORT TYPES
import {
    UPLOAD_URL1_FILE_START, UPLOAD_URL1_FILE_SUCCESS, UPLOAD_URL1_FILE_FAIL, ADD_SEMT_START, ADD_SEMT_SUCCESS,
    ADD_SEMT_FAILED, GET_SEMT_START, GET_SEMT_SUCCESS, GET_SEMT_FAILED, OPEN_DELETE_SEMT_MODAL, CLOSE_DELETE_SEMT_MODAL,
    DELETE_SEMT_START, DELETE_SEMT_SUCCESS, DELETE_SEMT_FAILED, UPDATE_SEMT_START, UPDATE_SEMT_SUCCESS, UPDATE_SEMT_FAILED,
    OPEN_UPDATE_SEMT_MODAL, CLOSE_UPDATE_SEMT_MODAL, UPLOAD_URL2_FILE_START, UPLOAD_URL2_FILE_SUCCESS, UPLOAD_URL2_FILE_FAIL,
    UPLOAD_URL3_FILE_START, UPLOAD_URL3_FILE_SUCCESS, UPLOAD_URL3_FILE_FAIL, UPLOAD_URL4_FILE_START, UPLOAD_URL4_FILE_SUCCESS,
    UPLOAD_URL4_FILE_FAIL, ADD_ILAN_START, ADD_ILAN_SUCCESS , ADD_ILAN_FAILED, GET_ILAN_START, GET_ILAN_SUCCESS, GET_ILAN_FAILED,
    SET_VIP_STATUS_START, SET_VIP_STATUS_SUCCESS, SET_VIP_STATUS_FAILED
} from "../../types/home/home";


export const uploadUrl1FileStart = () => {
    return {
        type: UPLOAD_URL1_FILE_START
    }
}

export const uploadUrl1FileSuccess = (url1) => {
    return {
        type: UPLOAD_URL1_FILE_SUCCESS,
        url1: url1
    }
}

export const uploadUrl1FileFail = () => {
    return {
        type: UPLOAD_URL1_FILE_FAIL
    }
}

export const uploadUrl1FileService = (data) => {
    const formData = new FormData();
    formData.append('file', data);

    return dispatch => {
        dispatch(uploadUrl1FileStart())
        fetch(UPLOAD_FILE_URL, {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
            .then(response => {
                console.log(response.filedestination)
                if (response.status === 200) {
                    dispatch(uploadUrl1FileSuccess(response.filedestination))
                }
                else {
                    dispatch(uploadUrl1FileFail())
                }
            })
            .catch((err) => {
                console.log("errrrrr ====>>>>", err)
            })
    }
}

export const addSemtStart = () => {
    return {
        type: ADD_SEMT_START
    }
}

export const addSemtSuccess = () => {
    return {
        type: ADD_SEMT_SUCCESS
    }
}

export const addSemtFail = (error) => {
    return {
        type: ADD_SEMT_FAILED,
        error: error
    }
}

export const addSemtService = (name, token) => {
    return dispatch => {
        dispatch(addSemtStart())
        fetch(ADD_SEMT_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify({
                "name": name
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(addSemtSuccess());
                }
                else {
                    dispatch(addSemtFail("error"))
                }
            })
            .catch(error => {
                dispatch(addSemtFail(error.message))
            })
    }
}

export const getSemtStart = () => {
    return {
        type: GET_SEMT_START
    }
}

export const getSemtSuccess = (semt) => {
    return {
        type: GET_SEMT_SUCCESS,
        semt: semt
    }
}

export const getSemFail = (error) => {
    return {
        type: GET_SEMT_FAILED,
        error: error
    }
}

export const getSemtService = (token) => {

    return dispatch => {
        dispatch(getSemtStart())
        fetch(GET_SEMT_URL, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log("===>>>>", response)
                if (response.responseStatus === 200) {
                    dispatch(getSemtSuccess(response.data.response));
                }
                else {
                    dispatch(getSemFail("error"))
                }
            })
            .catch(error => {
                dispatch(getSemFail(error.message))
            })
    }
}

export const openDeleteSemtModal = () => {
    return {
        type: OPEN_DELETE_SEMT_MODAL
    }
}

export const closeDeleteSemtModal = () => {
    return {
        type: CLOSE_DELETE_SEMT_MODAL
    }
}

export const deleteSemtStart = () => {
    return {
        type: DELETE_SEMT_START
    }
}

export const deleteSemtSuccess = () => {
    return {
        type: DELETE_SEMT_SUCCESS
    }
}

export const deleteSemtFail = (error) => {
    return {
        type: DELETE_SEMT_FAILED,
        error: error
    }
}

export const deleteSemtService = (semtid, token) => {
    return dispatch => {
        dispatch(deleteSemtStart())
        fetch(DELETE_SEMT_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify({
                "semtid": semtid
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(deleteSemtSuccess());
                    dispatch(closeDeleteSemtModal())
                    dispatch(getSemtService(token))
                }
                else {
                    dispatch(deleteSemtFail("error"))
                }
            })
            .catch(error => {
                dispatch(deleteSemtFail(error.message))
            })
    }
}

export const updateSemtStart = () => {
    return {
        type: UPDATE_SEMT_START
    }
}

export const updateSemtSuccess = () => {
    return {
        type: UPDATE_SEMT_SUCCESS
    }
}

export const updateSemtFail = (error) => {
    return {
        type: UPDATE_SEMT_FAILED,
        error: error
    }
}

export const updateSemtService = (semtid, name, token) => {
    return dispatch => {
        dispatch(updateSemtStart())
        fetch(UPDATE_SEMT_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify({
                "semtid": semtid,
                "name": name
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(updateSemtSuccess());
                    dispatch(closeUpdateSemtModal())
                    dispatch(getSemtService(token))
                }
                else {
                    dispatch(updateSemtFail("error"))
                }
            })
            .catch(error => {
                dispatch(updateSemtFail(error.message))
            })
    }
}

export const openUpdateSemtModal = () => {
    return {
        type: OPEN_UPDATE_SEMT_MODAL
    }
}

export const closeUpdateSemtModal = () => {
    return {
        type: CLOSE_UPDATE_SEMT_MODAL
    }
}

export const uploadUrl2FileStart = () => {
    return {
        type: UPLOAD_URL2_FILE_START
    }
}

export const uploadUrl2FileSuccess = (url2) => {
    return {
        type: UPLOAD_URL2_FILE_SUCCESS,
        url2: url2
    }
}

export const uploadUrl2FileFail = () => {
    return {
        type: UPLOAD_URL2_FILE_FAIL
    }
}

export const uploadUrl2FileService = (data) => {
    const formData = new FormData();
    formData.append('file', data);

    return dispatch => {
        dispatch(uploadUrl2FileStart())
        fetch(UPLOAD_FILE_URL, {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
            .then(response => {
                console.log(response.filedestination)
                if (response.status === 200) {
                    dispatch(uploadUrl2FileSuccess(response.filedestination))
                }
                else {
                    dispatch(uploadUrl2FileFail())
                }
            })
            .catch((err) => {
                console.log("errrrrr ====>>>>", err)
            })
    }
}

export const uploadUrl3FileStart = () => {
    return {
        type: UPLOAD_URL3_FILE_START
    }
}

export const uploadUrl3FileSuccess = (url3) => {
    return {
        type: UPLOAD_URL3_FILE_SUCCESS,
        url3: url3
    }
}

export const uploadUrl3FileFail = () => {
    return {
        type: UPLOAD_URL3_FILE_FAIL
    }
}

export const uploadUrl3FileService = (data) => {
    const formData = new FormData();
    formData.append('file', data);

    return dispatch => {
        dispatch(uploadUrl3FileStart())
        fetch(UPLOAD_FILE_URL, {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
            .then(response => {
                console.log(response.filedestination)
                if (response.status === 200) {
                    dispatch(uploadUrl3FileSuccess(response.filedestination))
                }
                else {
                    dispatch(uploadUrl3FileFail())
                }
            })
            .catch((err) => {
                console.log("errrrrr ====>>>>", err)
            })
    }
}

export const uploadUrl4FileStart = () => {
    return {
        type: UPLOAD_URL4_FILE_START
    }
}

export const uploadUrl4FileSuccess = (url4) => {
    return {
        type: UPLOAD_URL4_FILE_SUCCESS,
        url4: url4
    }
}

export const uploadUrl4FileFail = () => {
    return {
        type: UPLOAD_URL4_FILE_FAIL
    }
}

export const uploadUrl4FileService = (data) => {
    const formData = new FormData();
    formData.append('file', data);

    return dispatch => {
        dispatch(uploadUrl4FileStart())
        fetch(UPLOAD_FILE_URL, {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
            .then(response => {
                console.log(response.filedestination)
                if (response.status === 200) {
                    dispatch(uploadUrl4FileSuccess(response.filedestination))
                }
                else {
                    dispatch(uploadUrl4FileFail())
                }
            })
            .catch((err) => {
                console.log("errrrrr ====>>>>", err)
            })
    }
}

export const addIlanStart = () => {
    return {
        type: ADD_ILAN_START
    }
}

export const addIlanSuccess = () => {
    return {
        type: ADD_ILAN_SUCCESS
    }
}

export const addIlanFail = (error) => {
    return {
        type: ADD_ILAN_FAILED,
        error: error
    }
}

export const addIlanService = (semtid,title,  name, telephone, whatssap, contactlieu, telegram, yas, bilgi, zaman,ilandetail,url1,url2,url3,url4, token) => {
    return dispatch => {
        dispatch(addIlanStart())
        fetch(ADD_ILAN_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify({
                "semtid":semtid,
                "title": title,
                "name": name,
                "telephone": telephone,
                "whatssap": whatssap,
                "contactlieu": contactlieu,
                "telegram": telegram,
                "yas": yas,
                "bilgi": bilgi,
                "zaman": zaman,
                "ilandetail": ilandetail,
                "url1": url1,
                "url2": url2,
                "url3": url3,
                "url4": url4
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(addIlanSuccess());
                }
                else {
                    dispatch(addIlanFail("error"))
                }
            })
            .catch(error => {
                dispatch(addIlanFail(error.message))
            })
    }
}

export const getIlanStart = () => {
    return {
        type: GET_ILAN_START
    }
}

export const getIlanSuccess = (ilan) => {
    return {
        type: GET_ILAN_SUCCESS,
        ilan: ilan
    }
}

export const getIlanFail = (error) => {
    return {
        type: GET_ILAN_FAILED,
        error: error
    }
}

export const getIlanService = (token) => {

    return dispatch => {
        dispatch(getIlanStart())
        fetch(GET_ILAN_URL, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log("===>>>>", response)
                if (response.responseStatus === 200) {
                    dispatch(getIlanSuccess(response.data.response));
                }
                else {
                    dispatch(getIlanFail("error"))
                }
            })
            .catch(error => {
                dispatch(getIlanFail(error.message))
            })
    }
}

export const setVipStatusStart = () => {
    return {
        type: SET_VIP_STATUS_START
    }
}

export const setVipStatusSuccess = () => {
    return {
        type: SET_VIP_STATUS_SUCCESS
    }
}

export const setVipStatusFail = (error) => {
    return {
        type: SET_VIP_STATUS_FAILED,
        error: error
    }
}

export const setVipStatusService = (escortid,popular,token) => {
    return dispatch => {
        dispatch(setVipStatusStart())
        fetch(SET_VIP_STATUS_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify({
                "escortid": escortid,
                "popular":popular
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getIlanService(token));
                }
                else {
                    dispatch(setVipStatusFail("error"))
                }
            })
            .catch(error => {
                dispatch(setVipStatusFail(error.message))
            })
    }
}