export const UPLOAD_URL1_FILE_START = "UPLOAD_URL1_FILE_START";
export const UPLOAD_URL1_FILE_SUCCESS = "UPLOAD_URL1_FILE_SUCCESS";
export const UPLOAD_URL1_FILE_FAIL = "UPLOAD_URL1_FILE_FAIL";

export const ADD_SEMT_START = "ADD_SEMT_START";
export const ADD_SEMT_SUCCESS = "ADD_SEMT_SUCCESS";
export const ADD_SEMT_FAILED = "ADD_SEMT_FAILED";

export const GET_SEMT_START = "GET_SEMT_START";
export const GET_SEMT_SUCCESS = "GET_SEMT_SUCCESS";
export const GET_SEMT_FAILED = "GET_SEMT_FAILED";

export const OPEN_DELETE_SEMT_MODAL = "OPEN_DELETE_SEMT_MODAL";
export const CLOSE_DELETE_SEMT_MODAL = "CLOSE_DELETE_SEMT_MODAL";

export const DELETE_SEMT_START = "DELETE_SEMT_START";
export const DELETE_SEMT_SUCCESS = "DELETE_SEMT_SUCCESS";
export const DELETE_SEMT_FAILED = "DELETE_SEMT_FAILED";

export const UPDATE_SEMT_START = "UPDATE_SEMT_START";
export const UPDATE_SEMT_SUCCESS = "UPDATE_SEMT_SUCCESS";
export const UPDATE_SEMT_FAILED = "UPDATE_SEMT_FAILED";

export const OPEN_UPDATE_SEMT_MODAL = "OPEN_UPDATE_SEMT_MODAL";
export const CLOSE_UPDATE_SEMT_MODAL = "CLOSE_UPDATE_SEMT_MODAL";

export const UPLOAD_URL2_FILE_START = "UPLOAD_URL2_FILE_START";
export const UPLOAD_URL2_FILE_SUCCESS = "UPLOAD_URL2_FILE_SUCCESS";
export const UPLOAD_URL2_FILE_FAIL = "UPLOAD_URL2_FILE_FAIL";

export const UPLOAD_URL3_FILE_START = "UPLOAD_URL3_FILE_START";
export const UPLOAD_URL3_FILE_SUCCESS = "UPLOAD_URL3_FILE_SUCCESS";
export const UPLOAD_URL3_FILE_FAIL = "UPLOAD_URL3_FILE_FAIL";

export const UPLOAD_URL4_FILE_START = "UPLOAD_URL4_FILE_START";
export const UPLOAD_URL4_FILE_SUCCESS = "UPLOAD_URL4_FILE_SUCCESS";
export const UPLOAD_URL4_FILE_FAIL = "UPLOAD_URL4_FILE_FAIL";

export const ADD_ILAN_START = "ADD_ILAN_START";
export const ADD_ILAN_SUCCESS = "ADD_ILAN_SUCCESS";
export const ADD_ILAN_FAILED = "ADD_ILAN_FAILED";

export const GET_ILAN_START = "GET_ILAN_START";
export const GET_ILAN_SUCCESS = "GET_ILAN_SUCCESS";
export const GET_ILAN_FAILED = "GET_ILAN_FAILED";

export const SET_VIP_STATUS_START = "SET_VIP_STATUS_START";
export const SET_VIP_STATUS_SUCCESS = "SET_VIP_STATUS_SUCCESS";
export const SET_VIP_STATUS_FAILED = "SET_VIP_STATUS_FAILED";


