import * as actionTypes from '../../types/home/home'

const initialState = {
    loadingurl1: false,
    error: false,
    addsemtstatus: false,
    loadingsemt: false,
    semt: [],
    deletesemtmodal: false,
    updatesemtmodal: false,
    loadingdeletesemt: false,
    loadingupdatesemt: false,

    url1: "",
    url2: "",
    url3: "",
    url4: "",
    loadingurl2: false,
    loadingurl3: false,
    loadingurl4: false,

    addilanstatus: false,
    loadingaddilan: false,

    loadingilan: false,
    ilan: []

}

const ReducerHome = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.UPLOAD_URL1_FILE_START:
            return {
                ...state,
                loadingurl1: true,
                error: null
            }

        case actionTypes.UPLOAD_URL1_FILE_SUCCESS:
            return {
                ...state,
                loadingurl1: false,
                error: false,
                url1: action.url1
            }

        case actionTypes.UPLOAD_URL1_FILE_FAIL:
            return {
                ...state,
                error: action.error,
                loadingurl1: false
            }

        case actionTypes.ADD_SEMT_START:
            return {
                ...state,
                loadingsemt: true,
                error: null
            }

        case actionTypes.ADD_SEMT_SUCCESS:
            return {
                ...state,
                loadingsemt: false,
                addsemtstatus: true
            }

        case actionTypes.ADD_SEMT_FAILED:
            return {
                ...state,
                error: action.error,
                loadingsemt: false
            }

        case actionTypes.GET_SEMT_START:
            return {
                ...state,
                loadingsemt: true,
                addsemtstatus: false,
                error: null
            }

        case actionTypes.GET_SEMT_SUCCESS:
            return {
                ...state,
                loadingsemt: false,
                semt: action.semt
            }

        case actionTypes.GET_SEMT_FAILED:
            return {
                ...state,
                error: action.error,
                loadingsemt: false
            }

        case actionTypes.OPEN_DELETE_SEMT_MODAL:
            return {
                ...state,
                deletesemtmodal: true
            }

        case actionTypes.CLOSE_DELETE_SEMT_MODAL:
            return {
                ...state,
                deletesemtmodal: false
            }

        case actionTypes.OPEN_UPDATE_SEMT_MODAL:
            return {
                ...state,
                updatesemtmodal: true
            }

        case actionTypes.CLOSE_UPDATE_SEMT_MODAL:
            return {
                ...state,
                updatesemtmodal: false
            }

        case actionTypes.DELETE_SEMT_START:
            return {
                ...state,
                loadingdeletesemt: true,
                error: null
            }

        case actionTypes.DELETE_SEMT_SUCCESS:
            return {
                ...state,
                loadingdeletesemt: false
            }

        case actionTypes.DELETE_SEMT_FAILED:
            return {
                ...state,
                error: action.error,
                loadingdeletesemt: false
            }

        case actionTypes.UPDATE_SEMT_START:
            return {
                ...state,
                loadingupdatesemt: true,
                error: null
            }

        case actionTypes.UPDATE_SEMT_SUCCESS:
            return {
                ...state,
                loadingupdatesemt: false
            }

        case actionTypes.UPDATE_SEMT_FAILED:
            return {
                ...state,
                error: action.error,
                loadingupdatesemt: false
            }

        case actionTypes.UPLOAD_URL2_FILE_START:
            return {
                ...state,
                loadingurl2: true,
                error: null
            }

        case actionTypes.UPLOAD_URL2_FILE_SUCCESS:
            return {
                ...state,
                loadingurl2: false,
                error: false,
                url2: action.url2
            }

        case actionTypes.UPLOAD_URL2_FILE_FAIL:
            return {
                ...state,
                error: action.error,
                loadingurl2: false
            }

        case actionTypes.UPLOAD_URL3_FILE_START:
            return {
                ...state,
                loadingurl3: true,
                error: null
            }

        case actionTypes.UPLOAD_URL3_FILE_SUCCESS:
            return {
                ...state,
                loadingurl3: false,
                error: false,
                url3: action.url3
            }

        case actionTypes.UPLOAD_URL3_FILE_FAIL:
            return {
                ...state,
                error: action.error,
                loadingurl3: false
            }

        case actionTypes.UPLOAD_URL4_FILE_START:
            return {
                ...state,
                loadingurl4: true,
                error: null
            }

        case actionTypes.UPLOAD_URL4_FILE_SUCCESS:
            return {
                ...state,
                loadingurl4: false,
                error: false,
                url4: action.url4
            }

        case actionTypes.UPLOAD_URL4_FILE_FAIL:
            return {
                ...state,
                error: action.error,
                loadingurl4: false
            }

        case actionTypes.ADD_ILAN_START:
            return {
                ...state,
                loadingaddilan: true,
                error: null
            }

        case actionTypes.ADD_ILAN_SUCCESS:
            return {
                ...state,
                loadingaddilan: false,
                addilanstatus: true
            }

        case actionTypes.ADD_ILAN_FAILED:
            return {
                ...state,
                error: action.error,
                loadingaddilan: false
            }

        case actionTypes.GET_ILAN_START:
            return {
                ...state,
                loadingilan: true,
                addilanstatus: false,
                error: null
            }

        case actionTypes.GET_ILAN_SUCCESS:
            return {
                ...state,
                loadingilan: false,
                ilan: action.ilan
            }

        case actionTypes.GET_ILAN_FAILED:
            return {
                ...state,
                error: action.error,
                loadingilan: false
            }

        case actionTypes.SET_VIP_STATUS_START:
            return {
                ...state,
                loadingilan: true
            }

        case actionTypes.SET_VIP_STATUS_SUCCESS:
            return {
                ...state,
                loadingilan: false,
            }

        case actionTypes.SET_VIP_STATUS_FAILED:
            return {
                ...state,
                error: action.error,
                loadingilan: false
            }

        default:
            return state
    }
}

export default ReducerHome